<template>
    <div class="layout-px-spacing dash_1">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page"><span style="margin-left: 35px;"
                                        id="nome_cliente"></span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-recent-orders">
                    <div class="widget-content table-responsive">
                        <table style="margin-top: 20px;" class="table">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="th-content">Vencimento</div>
                                    </th>
                                    <th>
                                        <div class="th-content th-heading">Valor</div>
                                    </th>
                                    <th>
                                        <div class="th-content">Status</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cob in cobrancas_list">
                                    <td>
                                        <a :href="cob.link" target="_blank" rel="noopener noreferrer"
                                            :class="[{ 'a_disabled': cob.status === 'CONCLUIDA' }]">
                                            <div class="td-content">{{
                                                cob.vencimento.split("-").reverse().join("/").toString()
                                            }}</div>
                                        </a>
                                    </td>
                                    <td>
                                        <a :href="cob.link" target="_blank" rel="noopener noreferrer"
                                            :class="[{ 'a_disabled': cob.status === 'CONCLUIDA' }]">
                                            <div class="td-content"><span>{{
                                                cob.valor.toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                })
                                            }}</span></div>
                                        </a>
                                    </td>
                                    <td>
                                        <div class="td-content">
                                            <a :href="cob.link" target="_blank" rel="noopener noreferrer"
                                                :class="[{ 'a_disabled': cob.status === 'CONCLUIDA' }]">
                                                <span v-if="cob.vencido == 0"
                                                    :class="[{ 'badge badge-warning': cob.status === 'ATIVA' && cob.vencido == 0 }, { 'badge badge-success': cob.status === 'CONCLUIDA' && cob.vencido == 0 }]">
                                                    {{ cob.status.replace("ATIVA", "A Vencer").replace("CONCLUIDA",
                                                        "Pago") }}
                                                </span>
                                                <span v-if="cob.vencido == 1"
                                                    :class="[{ 'badge badge-danger': cob.status === 'ATIVA' && cob.vencido == 1 }, { 'badge badge-success': cob.status === 'CONCLUIDA' && cob.vencido == 1 }]">
                                                    <span v-if="cob.status === 'ATIVA'">Vencido</span>
                                                    <span v-if="cob.status === 'CONCLUIDA'">Pago</span>
                                                </span>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.a_disabled {
    pointer-events: none !important;
}
</style>

<script setup>
import '@/assets/sass/widgets/widgets.scss';
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import ApexChart from 'vue3-apexcharts';
import axios from 'axios';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'SKYvix - Faturas' });

const store = useStore();
const cobrancas_list = ref([]);

onMounted(() => {
    getFaturas();
    setNome();
});


const setNome = () => {
    document.querySelector("#nome_cliente").innerHTML = localStorage.getItem('cliente')
}

const getFaturas = () => {
    const formdata = new FormData()
    formdata.append('cpf', localStorage.getItem("token"));

    axios.post('./consultaFatura.php', formdata)
        .then((response) => {  

            cobrancas_list.value = response.data;
 
            // if(response.data.length > 2){
            //     if(response.data[1].status == 'ATIVA' && response.data[2].status == 'ATIVA'){
            //         response.data.splice(2, 1)
            //     }
            //     cobrancas_list.value = response.data;
            // }else{
            //     cobrancas_list.value = response.data;
            // }
        })
}



</script>
